import {useToast} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {add, differenceInMinutes, format, parse, parseISO, set, startOfToday} from "date-fns";
import {useLocation, useNavigate} from "react-router-dom";
import {Booking, Discount, NewExtension, SelectedSize, StorageSize} from "../../interfaces/interfaces";
import {useFetch} from "../../hooks/useFetch";
import {getSelectedSizeFromStorage, roundedHrs} from "../../helpers/common";
import {
    HTTPErrorCodeInvalidBookingDate,
    HTTPErrorCodeUnavailableSizes,
    PayExceededAndExtendTimePathname
} from "../../common/constants";
import {Detail} from "../common/Detail";
import {useTranslation} from "react-i18next";

const discountsURL = '/discount/all'
const newExtensionURL = '/extension/new'

export const ExtendDetail = ({
                                 booking: originalBooking = {} as Booking,
                                 cardRef = {} as React.RefObject<HTMLDivElement>,
                                 registerRef = {} as React.RefObject<HTMLDivElement>,
                                 height = ['auto'],
                                 goToPrev = [] as any
                             }) => {
    const {t: tCommon} = useTranslation('common');
    const {t, i18n: {language}} = useTranslation('detail');
    const toast = useToast()
    const navigate = useNavigate()
    const location = useLocation();

    const [hasTimeExceeded] =
        useState(location.pathname.includes(PayExceededAndExtendTimePathname))

    const [actualDateTime] = useState(set(new Date(), {seconds: 0, milliseconds: 0}))
    const storageFromDate = localStorage.getItem('fromDate') ?
        parse(localStorage.getItem('fromDate')!.toString(), 'dd-MM-yyyy', new Date()) : null
    const storageFromTime = localStorage.getItem('fromTime') ?
        parse(localStorage.getItem('fromTime')!.toString(), 'HH:mm', new Date()) : null
    const storageToDate = localStorage.getItem('toDate') ?
        parse(localStorage.getItem('toDate')!.toString(), 'dd-MM-yyyy', new Date()) : null
    const storageToTime = localStorage.getItem('toTime') ?
        parse(localStorage.getItem('toTime')!.toString(), 'HH:mm', new Date()) : null
    const [fromDate] = useState(storageFromDate ?
        storageFromDate : startOfToday());
    const [fromTime] = useState(storageFromTime ?
        storageFromTime : set(new Date(), {seconds: 0, milliseconds: 0}));
    const [toDate] = useState(storageToDate ?
        storageToDate : startOfToday());
    const [toTime] = useState(storageToTime ?
        storageToTime : add(fromTime, {hours: 1}));

    const [from] = useState(set(fromDate,
        {hours: fromTime.getHours(), minutes: fromTime.getMinutes()}));

    const [to] = useState(set(toDate,
        {hours: toTime.getHours(), minutes: toTime.getMinutes()}));

    const [selectedStorageSizes] =
        useState(localStorage.getItem('selectedSizes') ?
            JSON.parse(localStorage.getItem('selectedSizes')!) as StorageSize[] : [] as StorageSize[])

    const totalHrsToPay = roundedHrs(differenceInMinutes(to, parseISO(originalBooking.To)))
    const [exceededHrs, setExceededHrs] = useState(0)
    const [hrs, setHrs] = useState(roundedHrs(differenceInMinutes(to, from) -
        differenceInMinutes(parseISO(originalBooking.To), parseISO(originalBooking.From)))) // this default value is for a simple extension without exceeded time
    const [referralPercent] = useState(localStorage.getItem("referralPercent") ? localStorage.getItem("referralPercent") : null)
    const [discounts, setDiscounts] = useState([] as Discount[])
    const [selectedSizes, setSelectedSizes] = useState([] as SelectedSize[])
    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if (hasTimeExceeded) {
            const exceeded = roundedHrs(differenceInMinutes(actualDateTime, parseISO(originalBooking.To)))
            setExceededHrs(exceeded)
            setHrs(totalHrsToPay - exceeded)
        }
    }, [hasTimeExceeded]);

    const {
        data: allDiscounts = {} as Map<string, Discount>,
        fetch: fetchAllDiscounts
    } = useFetch()

    const {fetch: fetchNewExtension, isLoading: loadingNewExtension} = useFetch()


    const getInitialData = async () => {
        await fetchAllDiscounts('get', discountsURL, {})
    }

    // Scroll to the section when button is clicked
    const scrollToSection = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'})
    };

    useEffect(() => {
        scrollToSection(registerRef)
    }, []);

    useEffect(() => {
        getInitialData()
        setSelectedSizes(getSelectedSizeFromStorage(selectedStorageSizes))
    }, []);

    useEffect(() => {
        let aux = [] as Discount[]
        let discount = {} as Discount
        Object.entries(allDiscounts as Discount[])
            .forEach(([id, d]) => {
                if (d.DiscountType === "Time") {
                    if (hrs >= d.MinHours) {
                        discount = d;
                    }
                }
            })

        if (discount.ID) {
            aux.push(discount)
        }

        originalBooking.Discounts?.forEach((d) => {
            if (d.DiscountType !== "Time") {
                aux.push(d)
            }
        })

        setDiscounts(aux)
    }, [allDiscounts]);

    useEffect(() => {

        selectedSizes.sort((s1, s2) =>
            (s1.Size.ViewOrder <= s2.Size.ViewOrder ? -1 : 1))

        let sTotal = 0
        let total = 0
        selectedSizes.forEach((size, idx) => {
            let aux = hrs * size.SizeQty * size.Size.Price
            let exceeded = exceededHrs * size.SizeQty * size.Size.Price
            sTotal += exceeded + aux

            let perc = 0
            discounts.forEach((d) => {
                if (d.DiscountType !== "Time" && referralPercent) {
                    perc += Number(referralPercent)
                } else {
                    perc += Number(d.Percent)
                }
            })

            total += exceeded + (aux * Number(1 - (perc / 100)))
        })

        setSubTotal(sTotal)
        setTotal(total)
    }, [selectedSizes, discounts]);


    const confirm = async () => {
        const body: NewExtension = {
            From: from.toISOString(),
            To: to.toISOString(),
            BookingID: originalBooking.ID,
            TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        if (exceededHrs !== 0) {
            body.Exceeded = true
            body.ExceededHrs = exceededHrs
        }


        const error = await fetchNewExtension('post', newExtensionURL, body)

        if (error?.response?.data?.Code === HTTPErrorCodeUnavailableSizes) {
            toast({
                title: t('Errors.UnavailableSizes'),
                description: tCommon('Errors.IfNecessaryPleaseContactUs'),

                status: 'error',
                duration: 10000,
                isClosable: false,
            })
            return
        }

        if (error?.response?.data?.Code === HTTPErrorCodeInvalidBookingDate) {
            const f = format(fromTime, 'HH:mm') + " " + format(fromDate, 'dd-MM-yyyy')
            toast({
                title: t('Errors.InvalidBookingDate', {date: f}),
                description: tCommon('Errors.IfNecessaryPleaseContactUs'),
                status: 'error',
                duration: 10000,
                isClosable: false,
            })

            return
        }

        if (error) {
            toast({
                title: t('Errors.RentNotCreated'),
                description: tCommon('Errors.PleaseContactUs'),
                status: 'error',
                duration: 10000,
                isClosable: false,
            })

            return
        }

        toast({
            title: t('Success.RentCreated'),
            description: tCommon('Success.CheckInbox'),
            status: 'success',
            duration: 10000,
            isClosable: false,
        })

        navigate('/', {replace: true})
    }

    return (
        <Detail
            booking={{
                Location: originalBooking.Branch.City!,
                Branch: originalBooking.Branch,
                From: from.toString(),
                To: to.toISOString(),
                SelectedSizes: selectedSizes,
                Customer: {
                    Name: originalBooking.Customer.Name,
                    LastName: originalBooking.Customer.LastName,
                    Email: originalBooking.Customer.Email,
                    Phone: originalBooking.Customer.Phone,
                    DiscoveryMethod: originalBooking.Customer.DiscoveryMethod,
                    Language: language,
                }
            }}
            title={t('ExtensionTitle')}
            cardRef={cardRef}
            registerRef={registerRef}
            subTotal={subTotal}
            total={total}
            hrs={exceededHrs + hrs}
            confirm={confirm}
            height={height}
            discounts={discounts}
            goToPrev={goToPrev}
            loadingConfirm={loadingNewExtension}/>
    )
}