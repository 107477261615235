import {useToast} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {addHours, differenceInMinutes, differenceInSeconds, isBefore, isEqual, parseISO, set} from "date-fns";
import {useNavigate} from "react-router-dom";
import {Booking, PayExtraTime, SelectedSize, Size, StorageSize} from "../../interfaces/interfaces";
import {useFetch} from "../../hooks/useFetch";
import {getSelectedSizeFromStorage, roundedHrs} from "../../helpers/common";
import {Detail} from "../common/Detail";
import {useTranslation} from "react-i18next";

const payExceededURL = '/pay_exceeded'

export const PayExceededDetail = ({
                                      bookingToPay = {} as Booking,
                                      cardRef = {} as React.RefObject<HTMLDivElement>,
                                      registerRef = {} as React.RefObject<HTMLDivElement>,
                                      height = ['fit-content', 'auto'],
                                  }) => {
    const {t: tCommon} = useTranslation('common');
    const {t, i18n: {language}} = useTranslation('detail');
    const toast = useToast()
    const navigate = useNavigate()

    const [from] = useState(parseISO(bookingToPay.From));
    const [to, setTo] = useState(new Date());
    const [remainingMinutes, setRemainingMinutes] = useState(0);

    const [selectedStorageSizes] =
        useState(localStorage.getItem('selectedSizes') ?
            JSON.parse(localStorage.getItem('selectedSizes')!) as StorageSize[] : [] as StorageSize[])

    const [hrs, setHrs] = useState(0)
    const [selectedSizes, setSelectedSizes] = useState([] as SelectedSize[])
    const [total, setTotal] = useState(0)

    const {fetch: fetchPayExceeded, isLoading: loadingPayExceeded} = useFetch()

    // Scroll to the section when button is clicked
    const scrollToSection = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'})
    };

    useEffect(() => {
        let upto = set(new Date(), {minutes: parseISO(bookingToPay.To).getMinutes(), seconds: 0, milliseconds: 0})

        if (isBefore(upto, new Date()) || isEqual(upto, new Date())) {
            upto = addHours(upto, 1)
        }

        if (differenceInSeconds(upto, new Date()) < 900) { //900
            setRemainingMinutes(differenceInMinutes(upto, new Date()))
        }

        setTo(upto)
        setHrs(roundedHrs(differenceInMinutes(new Date(), parseISO(bookingToPay.To))))
    }, []);

    useEffect(() => {
        scrollToSection(registerRef)
    }, []);

    useEffect(() => {
        setSelectedSizes(getSelectedSizeFromStorage(selectedStorageSizes))
    }, []);

    useEffect(() => {
        selectedSizes.sort((s1, s2) =>
            (s1.Size?.ViewOrder <= s2.Size?.ViewOrder ? -1 : 1))

        let sTotal = 0
        selectedSizes.forEach((size, idx) => {
            sTotal += hrs * size.SizeQty * size.Size?.Price
        })

        setTotal(sTotal)
    }, [selectedSizes, hrs]);

    useEffect(() => {
        if (bookingToPay !== undefined && bookingToPay.Spaces) {
            const auxList = [] as SelectedSize[]
            Object.entries((bookingToPay as Booking).Spaces).map(([k, space]) => {
                const newAux: {
                    Size: Size
                    SizeQty: number
                } = {Size: space.Size, SizeQty: 1}

                let add = true
                for (let i = 0; i < auxList.length; i++) {
                    if (auxList[i].Size?.ID === newAux.Size?.ID) {
                        auxList[i].SizeQty++
                        add = false
                        break
                    }

                }

                if (add) {
                    auxList.push(newAux)
                }
            })

            setSelectedSizes(auxList)
        }

    }, [bookingToPay]);

    useEffect(() => {
    }, [bookingToPay, selectedSizes]);

    const confirm = async () => {
        const body: PayExtraTime = {
            From: from.toISOString(),
            To: to.toISOString(),
            ExceededHrs: hrs,
            BookingID: (bookingToPay as Booking)?.ID,
            TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        const error = await fetchPayExceeded('post', payExceededURL, body)

        if (error) {
            toast({
                title: t('Errors.OrderNotCreated'),
                description: tCommon('Errors.PleaseContactUs'),
                status: 'error',
                duration: 10000,
                isClosable: false,
            })

            return
        }

        toast({
            title: t('Success.OrderCreated'),
            description: tCommon('Success.CheckInbox'),
            status: 'success',
            duration: 10000,
            isClosable: false,
        })

        navigate('/', {replace: true})
    }

    return (
        <Detail
            booking={{
                Location: bookingToPay.Branch.City,
                Branch: bookingToPay.Branch,
                From: from.toISOString(),
                To: to.toISOString(),
                SelectedSizes: selectedSizes,
                Customer: {
                    Name: bookingToPay.Customer.Name,
                    LastName: bookingToPay.Customer.LastName,
                    Email: bookingToPay.Customer.Email,
                    Phone: bookingToPay.Customer.Phone,
                    DiscoveryMethod: bookingToPay.Customer.DiscoveryMethod,
                    Language: language,
                }
            }}
            title={t('TimeExceededTitle')}
            cardRef={cardRef}
            registerRef={registerRef}
            total={total}
            hrs={hrs}
            confirm={confirm}
            height={height}
            showGoToPrev={false}
            loadingConfirm={loadingPayExceeded}
            remainingMinutes={remainingMinutes}
        />
    )
}