import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Checkbox,
    Collapse,
    Divider,
    Heading,
    HStack,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Text,
    VStack
} from "@chakra-ui/react";
import {NextBtn} from "../common/NextBtn";
import {PrevBtn} from "../common/PrevBtn";
import React, {RefObject, useEffect, useState} from "react";
import {validate} from "../../helpers/validation";
import {PhoneCodeSelector} from "./PhoneCodeSelector";
import {CountryCode} from "../../interfaces/phone_code";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faChevronDown, faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {useFetch} from "../../hooks/useFetch";
import {useTranslation} from "react-i18next";
import {REFERRAL_API} from "../../api/api";
import {Referrer} from "../../interfaces/user";


export const Customer = ({
                             cardRef = {} as RefObject<HTMLDivElement>,
                             registerRef = {} as React.RefObject<HTMLDivElement>,
                             height = ['auto'],
                             goToPrev = [] as any,
                             goToNext = [] as any
                         }) => {
    const {t, i18n: {language}} = useTranslation('customer');
    const [hasReferrer, setHasReferrer] = useState(!!localStorage.getItem("hasReferrer"));
    const [isValidReferrer, setIsValidReferrer] = useState(!!localStorage.getItem("isValidReferrer"))

    const [customerName, setCustomerName] = useState(
        localStorage.getItem("customerName") ? localStorage.getItem("customerName")!.toString() : '')

    const [customerLastName, setCustomerLastName] = useState(
        localStorage.getItem("customerLastName") ? localStorage.getItem("customerLastName")!.toString() : '')

    const [customerEmail, setCustomerEmail] = useState(
        localStorage.getItem("customerEmail") ? localStorage.getItem("customerEmail")!.toString() : '')

    const [customerPhone, setCustomerPhone] = useState(
        localStorage.getItem("customerPhone") ? localStorage.getItem("customerPhone")!.toString() : '')

    const [countryCode, setCountryCode] = useState(
        localStorage.getItem("countryCode") ?
            JSON.parse(localStorage.getItem("countryCode")!) as CountryCode : {} as CountryCode);

    const [discoveryMethod, setDiscoveryMethod] = useState(
        localStorage.getItem("discoveryMethod") ? localStorage.getItem("discoveryMethod")!.toString() : '')

    const [errors, setErrors] =
        useState<Map<string, string>>(new Map());

    useEffect(() => {
    }, [errors]);

    const handleNameChange = ({target}: any) => {
        localStorage.setItem('customerName', target.value.trim());
        setCustomerName(target.value);
    }

    const handleLastNameChange = ({target}: any) => {
        localStorage.setItem('customerLastName', target.value.trim());
        setCustomerLastName(target.value);
    }

    const handleEmailChange = ({target}: any) => {
        localStorage.setItem('customerEmail', target.value.trim());
        setCustomerEmail(target.value.toLowerCase());
    }

    const handlePhoneChange = ({target}: any) => {
        setCustomerPhone(target.value)
        localStorage.setItem('customerPhone', target.value)
    }

    const handleDiscoveryMethodChange = (value: string) => {
        localStorage.setItem('discoveryMethod', value)
        setDiscoveryMethod(value)
    }

    // Scroll to the section when button is clicked
    const scrollToSection = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    const handleSetCountryCode = (value = {} as CountryCode) => {
        setCountryCode(value)
        localStorage.setItem('countryCode', JSON.stringify(value))
    }

    useEffect(() => {
        scrollToSection(registerRef)
    }, []);

    const check = (inputName: string, value: string) => {
        setErrors(validate(inputName, language, value));
    }

    const handleRemoveError = (name: string) => {
        const m = errors;
        m.delete(name)
        setErrors(new Map(m))
    }

    const isAllCompleted = () => {
        const valid = customerName && customerLastName && customerEmail && customerPhone && discoveryMethod
        return (!hasReferrer && valid) || (hasReferrer && isValidReferrer && valid)
    }

    return (
        <>
            <Card ref={cardRef} bgColor={'secondary.500'} color={'lightBrand'}
                  h={height}
                  borderRadius={20} p={4} w={'100%'}>
                <CardHeader>
                    <Heading fontSize={[22, 22, 24]}>
                        {t('Title')}
                    </Heading>
                </CardHeader>

                <CardBody p={2}>
                    <VStack spacing='4'>
                        <CustomerInput heading={t('Name')}
                                       type={'text'}
                                       placeholder={t('Name')}
                                       inputName={'name'}
                                       validate={check}
                                       errors={errors}
                                       value={customerName}
                                       handleChange={handleNameChange}
                                       handleRemoveError={handleRemoveError}/>
                        <Divider/>
                        <CustomerInput heading={t('LastName')}
                                       type={'text'}
                                       placeholder={t('LastName')}
                                       inputName={'lastName'}
                                       validate={check}
                                       errors={errors}
                                       value={customerLastName}
                                       handleChange={handleLastNameChange}
                                       handleRemoveError={handleRemoveError}/>

                        <Divider/>
                        <CustomerInput
                            heading={t('Email')}
                            type={'email'}
                            placeholder={t('Email')}
                            inputName={'email'}
                            errors={errors}
                            value={customerEmail}
                            validate={check}
                            handleChange={handleEmailChange}
                            handleRemoveError={handleRemoveError}/>
                        <Divider/>
                        <PhoneInput
                            countryCode={countryCode}
                            setCountryCode={handleSetCountryCode}
                            heading={t('Phone')}
                            type={'number'}
                            placeholder={t('Phone')}
                            inputName={'phone'}
                            errors={errors}
                            value={customerPhone}
                            validate={check}
                            handleChange={handlePhoneChange}
                            handleRemoveError={handleRemoveError}/>
                        <Divider/>
                        <DiscoveryMethodInput
                            heading={t('HowDoYouFindUs')}
                            inputName={'discoveryMethod'}
                            errors={errors}
                            value={discoveryMethod}
                            validate={check}
                            handleChange={handleDiscoveryMethodChange}
                            handleRemoveError={handleRemoveError}/>
                        <Divider/>
                        <ReferrerInfo
                            heading={t('HowDoYouFindUs')}
                            inputName={'referrer'}
                            errors={errors}
                            value={discoveryMethod}
                            validate={check}
                            hasReferrer={hasReferrer}
                            setHasReferrer={setHasReferrer}
                            isValidReferrer={isValidReferrer}
                            setIsValidReferrer={setIsValidReferrer}
                            handleRemoveError={handleRemoveError}
                        />
                        <Divider/>
                        <HStack w={'100%'} justifyContent={'center'} position={'relative'} bottom={0}>
                            <PrevBtn prevStep={goToPrev}/>
                            <NextBtn isDisabled={!isAllCompleted() || errors.size > 0} nextStep={goToNext}/>
                        </HStack>
                    </VStack>
                </CardBody>
            </Card>
        </>
    )
}

const DiscoveryMethodInput = ({
                                  heading = '',
                                  inputName = '',
                                  value = '',
                                  handleChange = [] as any,
                                  validate = [] as any,
                                  handleRemoveError = [] as any,
                                  errors = {} as Map<string, string>
                              }) => {
    const {t} = useTranslation('customer');
    const {
        data: discoveryMethods = {} as string[],
        fetch: fetchDiscoveryMethods,
    } = useFetch()


    useEffect(() => {
        fetchDiscoveryMethods("get", "/discovery_methods", {})
    }, []);

    return (
        <VStack w={'100%'}>
            <Heading fontSize={[14, 14, 16]} w={'100%'} textAlign={'left'}
                     textTransform='uppercase'>
                {heading}
            </Heading>
            <Menu matchWidth={true} placement={'bottom'} flip={false}>
                <MenuButton
                    bgColor={'secondary.500'}
                    borderColor={'lightBrand'}
                    outline={'none'}
                    py={2}
                    w={'100%'}
                    transition='all 0.2s'
                    borderRadius='md'
                    borderWidth='1px'
                    _hover={{borderColor: 'lightBrand'}}
                    _focus={{borderColor: 'lightBrand'}}
                >
                    <HStack justify={'space-between'} w={'100%'} pe={3}>
                        <Text me={2} ms={3}>
                            {value ? t(`${value}`) : t('ChooseAnOption')}
                        </Text>
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </HStack>
                </MenuButton>
                <MenuList
                    opacity={0.8} bgColor={'secondary.500'}
                    borderColor={'lightBrand'} overflowY={'scroll'} maxHeight={200} w={'100%'}
                    onBlur={() => validate(inputName, value)}
                    onFocus={() => handleRemoveError(inputName)}>
                    {discoveryMethods.map((method, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => handleChange(method)}
                            my={2}
                            bgColor={value === method ? 'primary.500' : 'secondary.500'}
                            color={'lightBrand'}
                            justifyContent={'center'}
                            _hover={{bgColor: 'primary.500'}}>
                            {t(`${method}`)}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
            <Stack w={'100%'}>
                <Collapse in={errors.has(inputName)} animateOpacity>
                    <HStack spacing={2} w={'100%'} justify={'left'} mt={2}>
                        <FontAwesomeIcon color={'tomato'} icon={faCircleExclamation}/>
                        <Text textAlign={'left'} color={'tomato'} fontSize={14} w={'100%'}>
                            {errors.get(inputName)}
                        </Text>
                    </HStack>
                </Collapse>
            </Stack>
        </VStack>
    )
}

const PhoneInput = ({
                        countryCode = {} as CountryCode,
                        setCountryCode = [] as any,
                        heading = '', value = '',
                        type = '',
                        inputName = '',
                        placeholder = '',
                        handleChange = [] as any,
                        validate = [] as any,
                        handleRemoveError = [] as any,
                        errors = {} as Map<string, string>
                    }) => {

    return (
        <VStack w={'100%'}>
            <Heading fontSize={[14, 14, 16]} w={'100%'} textAlign={'left'}
                     textTransform='uppercase'>
                {heading}
            </Heading>
            <HStack w={'100%'}>
                <PhoneCodeSelector
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}/>
                <Input mt={2}
                       textAlign={'left'}
                       width={'100%'}
                       type={type}
                       placeholder={placeholder}
                       value={value}
                       size={'md'}
                       onChange={handleChange}
                       onBlur={() => validate(inputName, value)}
                       onFocus={() => handleRemoveError(inputName)}
                />
            </HStack>

            <Stack w={'100%'}>
                <Collapse in={errors.has(inputName)} animateOpacity>
                    <HStack spacing={2} w={'100%'} justify={'left'} mt={2}>
                        <FontAwesomeIcon color={'tomato'} icon={faCircleExclamation}/>
                        <Text textAlign={'left'} color={'tomato'} fontSize={14} w={'100%'}>
                            {errors.get(inputName)}
                        </Text>
                    </HStack>
                </Collapse>
            </Stack>
        </VStack>
    )
}

const CustomerInput = ({
                           heading = '', value = '',
                           type = '',
                           inputName = '',
                           placeholder = '',
                           handleChange = [] as any,
                           validate = [] as any,
                           handleRemoveError = [] as any,
                           errors = {} as Map<string, string>
                       }) => {
    return (
        <VStack w={'100%'}>
            <Heading fontSize={[14, 14, 16]} w={'100%'} textAlign={'left'}
                     textTransform='uppercase'>
                {heading}
            </Heading>
            <Input mt={2}
                   textAlign={'left'}
                   width={'100%'}
                   type={type}
                   placeholder={placeholder}
                   value={value}
                   size={'md'}
                   onChange={handleChange}
                   onBlur={() => validate(inputName, value)}
                   onFocus={() => handleRemoveError(inputName)}
            />
            <Stack w={'100%'}>
                <Collapse in={errors.has(inputName)} animateOpacity>
                    <HStack spacing={2} w={'100%'} justify={'left'} mt={2}>
                        <FontAwesomeIcon color={'tomato'} icon={faCircleExclamation}/>
                        <Text textAlign={'left'} color={'tomato'} fontSize={14} w={'100%'}>
                            {errors.get(inputName)}
                        </Text>
                    </HStack>
                </Collapse>
            </Stack>
        </VStack>
    )
}

const ReferrerInfo = ({
                          heading = '', value = '',
                          type = '',
                          inputName = '',
                          placeholder = '',
                          validate = [] as any,
                          handleRemoveError = [] as any,
                          hasReferrer = false,
                          setHasReferrer = (hasReferrer: boolean) => {
                          },
                          isValidReferrer = false,
                          setIsValidReferrer = (valid: boolean) => {
                          },
                          errors = {} as Map<string, string>
                      }) => {
    const {t} = useTranslation('customer');
    useEffect(() => {
        if (!hasReferrer) {
            setReferrerInfo("");
            setIsValidReferrer(false)
            setIsReferrerValidated(false)
            localStorage.removeItem('isValidReferrer');
            localStorage.removeItem('isReferrerValidated');
            localStorage.removeItem('referrerInfo');
            localStorage.removeItem('referralPercent');
        }
    }, [hasReferrer]);

    const [isReferrerValidated, setIsReferrerValidated] = useState(!!localStorage.getItem("isReferrerValidated"))
    const [referrerInfo, setReferrerInfo] = useState(
        localStorage.getItem("referrerInfo") ? localStorage.getItem("referrerInfo")!.toString() : '')

    const {
        data: referrer = {} as Referrer | null,
        fetch: fetchReferrer, isLoading: loadingReferrerData,
    } = useFetch(REFERRAL_API)

    useEffect(() => {
        if (!(referrer as Referrer) || Array.isArray(referrer)) {
            return
        }

        console.log(referrer)

        const referr = referrer as Referrer;
        if (referr.PassengerPercent) {
            localStorage.setItem('referralPercent', referr.PassengerPercent);
        } else {
            localStorage.removeItem('referralPercent');
            console.log("removing referral percent");
        }

    }, [referrer]);

    const handleReferralInfoChange = ({target}: any) => {
        localStorage.setItem('referrerInfo', target.value.trim());
        setReferrerInfo(target.value.toLowerCase());
        setIsValidReferrer(false)
        localStorage.removeItem('isValidReferrer');
    }

    const handleHasReferral = () => {
        setHasReferrer(!hasReferrer)
        if (!hasReferrer) {
            localStorage.setItem('hasReferrer', "true");
            return
        }

        localStorage.removeItem('hasReferrer');
    }

    const checkReferrerInfo = async () => {
        const error = await fetchReferrer('get', "/referrer/" + referrerInfo, {})
        if (error) {
            setIsValidReferrer(false)
            localStorage.removeItem('isValidReferrer');
        } else {
            setIsValidReferrer(true)
            localStorage.setItem('isValidReferrer', "true");
        }

        setIsReferrerValidated(true)
        localStorage.setItem('isReferrerValidated', "true");
    }

    return (
        <>
            <Checkbox size='md' colorScheme='primary' alignSelf={"start"} isChecked={hasReferrer}
                      onChange={handleHasReferral}>
                {t('ReferrerCodeCheck')}
            </Checkbox>
            <HStack w={"100%"}>
                <Collapse in={hasReferrer} animateOpacity style={{width: '100%'}}>
                    <VStack w={"100%"}>
                        <Heading fontSize={[14, 14, 16]} w={'100%'} textAlign={'left'}
                                 textTransform='uppercase'>
                            {t('ReferrerCodeLabel')}
                        </Heading>
                        <HStack mt={2} w={"100%"}>
                            <Input
                                textAlign={'left'}
                                width={'100%'}
                                type={"text"}
                                placeholder={t('ReferrerCodeLabel')}
                                value={referrerInfo}
                                size={'md'}
                                onChange={handleReferralInfoChange}
                                onBlur={() => validate(inputName, referrerInfo)}
                                onFocus={() => handleRemoveError(inputName)}
                            />
                            <Button w={'40%'} minW={90}
                                    _hover={{bgColor: 'green.600'}}
                                    bg={'green'} color={'lightBrand'}
                                    onClick={checkReferrerInfo}
                                    isDisabled={!referrerInfo}
                                    isLoading={loadingReferrerData}>
                                {t('ValidateReferrerCode')}
                            </Button>
                        </HStack>
                        <Stack w={'100%'}>
                            <Collapse in={isValidReferrer && isReferrerValidated && referrerInfo !== ""}
                                      animateOpacity>
                                <HStack spacing={2} w={'100%'} justify={'left'} mt={2}>
                                    <FontAwesomeIcon color={'green'} icon={faCheckCircle}/>
                                    <Text textAlign={'left'} color={'green'} fontSize={14} w={'100%'}>
                                        {t('ReferrerCodeValidated')}
                                    </Text>
                                </HStack>
                            </Collapse>
                            <Collapse in={!isValidReferrer && isReferrerValidated && referrerInfo !== ""}
                                      animateOpacity>
                                <HStack spacing={2} w={'100%'} justify={'left'} mt={2}>
                                    <FontAwesomeIcon color={'tomato'} icon={faCircleExclamation}/>
                                    <Text textAlign={'left'} color={'tomato'} fontSize={14} w={'100%'}>
                                        {t('InvalidReferrerCode')}
                                    </Text>
                                </HStack>
                            </Collapse>
                        </Stack>
                    </VStack>
                </Collapse>
            </HStack>
        </>
    )
}