import {
    Card,
    CardBody,
    CardHeader,
    Divider,
    Heading,
    HStack,
    ListItem,
    Stack,
    Text,
    UnorderedList,
    useToast,
    VStack
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {
    add,
    addDays,
    addHours,
    format,
    isAfter,
    isBefore,
    isEqual,
    isSameDay,
    isToday,
    set,
    startOfDay,
    startOfToday
} from "date-fns";
import {DatePicker} from "../common/DatePicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {Discount} from "../../interfaces/interfaces";
import {useFetch} from "../../hooks/useFetch";
import {CustomSkeleton} from "../common/CustomSkeleton";
import {PrevBtn} from "../common/PrevBtn";
import {NextBtn} from "../common/NextBtn";
import {FromTimePicker} from "./FromTimePicker";
import {ToTimePicker} from "./ToTimePicker";
import {useCountDownTimer} from "../../hooks/useCountDownTimer";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toZonedTime} from "date-fns-tz";


const minBookHrs = 2
const discountsURL = '/discount/all'

export const DateSelection = ({
                                  cardRef = {} as React.RefObject<HTMLDivElement>,
                                  registerRef = {} as React.RefObject<HTMLDivElement>,
                                  height = ['auto'],
                                  goToPrev = [] as any,
                                  goToNext = [] as any
                              }) => {
    const {t} = useTranslation('dateSelection');
    const {t: tCommon} = useTranslation('common');
    const navigate = useNavigate()
    const [discounts, setDiscounts] = useState<Map<string, Discount>>(new Map<string, Discount>())
    const toast = useToast()
    const [actualDateTime, setActualDateTime] = useState(set(toZonedTime(new Date(), 'America/Argentina/Buenos_Aires'), {
        seconds: 0,
        milliseconds: 0
    })) // 5 minutes
    const {countDown} = useCountDownTimer(1200)


    const [fromDate, setFromDate] = useState(set(toZonedTime(new Date(), 'America/Argentina/Buenos_Aires'), {
        seconds: 0,
        milliseconds: 0
    }));
    const [fromTime, setFromTime] = useState(set(toZonedTime(new Date(), 'America/Argentina/Buenos_Aires'), {
        seconds: 0,
        milliseconds: 0
    }));
    const [toDate, setToDate] = useState(toZonedTime(startOfToday(), 'America/Argentina/Buenos_Aires'));
    const [toTime, setToTime] = useState(add(fromTime, {hours: minBookHrs}));
    const [now, setNow] = useState(true);
    const [minTime, setMinTime] = useState(
        isSameDay(add(fromTime, {hours: minBookHrs}), toTime) ? add(fromTime, {hours: minBookHrs}) : startOfDay(fromTime)
    );

    const {
        data: allDiscounts = {} as Map<string, Discount>,
        fetch: fetchDiscounts, isLoading: loadingDiscounts
    } = useFetch()

    const getDiscounts = async () => {
        const error = await fetchDiscounts('get', discountsURL, {})
        if (error) {
            if (!toast.isActive("InternalServerError")) {
                toast({
                    id: "InternalServerError",
                    title: tCommon('Errors.InternalServerError'),
                    description: tCommon('Errors.PleaseContactUs'),
                    status: 'error',
                    duration: 10000,
                    isClosable: false,
                })
            }

            navigate("/", {replace: true})
            return
        }
    }

    const handleGoToPrev = () => {
        localStorage.removeItem('fromDate')
        localStorage.removeItem('fromTime')
        localStorage.removeItem('toDate')
        localStorage.removeItem('toTime')
        localStorage.removeItem('isNowBook')
        goToPrev()
    }

    useEffect(() => {
        getDiscounts()
    }, []);

    // Scroll to the section when button is clicked
    const scrollToSection = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        scrollToSection(registerRef)
    }, []);

    useEffect(() => {
        localStorage.setItem('fromDate', format(fromDate, 'dd-MM-yyyy'))
        localStorage.setItem('fromTime', format(fromTime, 'HH:mm'))
        localStorage.setItem('toDate', format(toDate, 'dd-MM-yyyy'))
        localStorage.setItem('toTime', format(toTime, 'HH:mm'))
        localStorage.setItem('isNowBook', now ? 'true' : 'false');
    }, [fromDate, fromTime, toDate, toTime, now]);

    useEffect(() => {
        setNow(false);

        const isNow = isEqual(actualDateTime, fromTime)
        if (isNow) {
            setNow(true);
        }

        if (fromTime.getMinutes() !== 0) {
            setToTime(set(toDate, {
                hours: toTime.getHours(),
                minutes: fromTime.getMinutes()
            }))
        } else {
            setToTime(set(toDate, {
                hours: toTime.getHours(),
                minutes: 0
            }))
        }
    }, [fromTime]);

    useEffect(() => {
        setMinTime(getToPickerMinTime())
        if (shouldChangeToDateToNextDay(toDate, fromTime)) {
            setToDate(addDays(toDate, 1))
        }
    }, [fromTime, toTime, fromDate, toDate])

    useEffect(() => {
        const actual = set(new Date(), {seconds: 0, milliseconds: 0})
        setActualDateTime(actual)
    }, [countDown]);

    const handleFromDateChange = (value: Date) => {
        const newTime = set(value, {hours: fromTime.getHours(), minutes: fromTime.getMinutes()})
        setFromDate(newTime);
        setFromTime(newTime);

        if (isAfter(addHours(newTime, 2), toTime)) {
            setToTime(addHours(newTime, minBookHrs))
        }

        if (isFromDateAfterToDate(value)) {
            setToDate(value);
        }

        if (!isToday(value)) {
            const aux = set(value, {hours: fromTime.getHours(), minutes: 0})
            setFromTime(aux);
        }

        if (isToday(value) && isTimeBeforeThanNow(newTime)) {
            setFromTime(actualDateTime);
        }

        if (shouldChangeToDateToNextDay(toDate, newTime)) {
            setToDate(addDays(toDate, 1));
        }
    }

    const handleToDateChange = (value: Date) => {
        const newTime = set(value, {hours: toTime.getHours(), minutes: toTime.getMinutes()})
        setToDate(newTime)
        setToTime(newTime)

        /* if (isAfter(addHours(newTime, 2), toTime)) {
             setToTime(addHours(newTime, minBookHrs))
         }*/

        if (shouldChangeToDateToNextDay(newTime, fromTime)) {
            setToDate(addDays(newTime, 1));
        }
    }

    const handleFromTimeChange = (value: Date) => {
        const newTime = set(fromDate, {hours: value.getHours(), minutes: value.getMinutes()})
        setFromTime(newTime)

        if (isAfter(addHours(newTime, 2), toTime)) {
            setToTime(addHours(newTime, minBookHrs))
        }

        if (shouldChangeToDateToNextDay(toDate, newTime)) {
            setToDate(addDays(toDate, 1));
        }
    }

    const handleToTimeChange = (value: Date) => {
        const newTime = set(toDate, {hours: value.getHours(), minutes: value.getMinutes()})
        setToTime(newTime)
    }

    const getToPickerMinTime = () => {
        const f = addHours(fromTime, minBookHrs)
        if (isSameDay(f, toTime)) {
            return f
        }

        return startOfDay(toTime);
    }

    const isFromDateAfterToDate = (f: Date) => {
        return isAfter(f, toTime)
    }

    const isTimeBeforeThanNow = (t: Date) => {
        return isBefore(t, new Date())
    }

    const shouldChangeToDateToNextDay = (toDate: Date, t: Date) => {
        const aux = addHours(t, minBookHrs)
        return isSameDay(aux, addDays(toDate, 1))
    }

    return (
        <Card ref={cardRef} bgColor={'secondary.500'} color={'lightBrand'}
              h={height}
              borderRadius={20} p={4} w={'100%'}>
            <CardHeader>
                <Heading fontSize={[22, 22, 24]}>
                    {t('Title')}
                </Heading>
            </CardHeader>

            <CardBody p={2}>
                <VStack spacing={4}>
                    <VStack spacing={4} w={'100%'}>
                        <Heading fontSize={[14, 14, 16]} w={'100%'} textAlign={'left'}
                                 textTransform='uppercase'>
                            {t('From')}
                        </Heading>
                        <Stack direction={['column', 'column', 'row', 'row']} w={'100%'} justify={'space-around'}>
                            <DatePicker isFromPicker={true}
                                        minDate={startOfToday()} date={fromDate}
                                        setDate={handleFromDateChange}/>
                            <FromTimePicker showNowItem={isToday(fromDate)}
                                            minTime={actualDateTime}
                                            now={now}
                                            setNow={setNow}
                                            time={fromTime}
                                            setTime={(t: Date) => handleFromTimeChange(t)}/>
                        </Stack>
                    </VStack>
                    <Divider/>
                    <VStack spacing={4} w={'100%'}>
                        <HStack w={'100%'}>
                            <Heading fontSize={[14, 14, 16]} me={4} textAlign={'left'}
                                     textTransform='uppercase'>
                                {t('To')}
                            </Heading>
                            <HStack spacing={2} w={'100%'}>
                                <FontAwesomeIcon fontSize={22} icon={faCircleInfo}/>
                                <Text fontSize={[14, 16]}>
                                    {t('MinTime')}{minBookHrs.toString()} hrs.
                                </Text>
                            </HStack>
                        </HStack>
                        <Stack direction={['column', 'column', 'row', 'row']} w={'100%'} justify={'space-around'}>
                            <DatePicker minDate={startOfDay(add(fromTime, {hours: minBookHrs}))}
                                        date={toDate} setDate={handleToDateChange}/>
                            <ToTimePicker minTime={minTime}
                                          fromTime={fromTime}
                                          time={toTime}
                                          discounts={allDiscounts}
                                          setTime={handleToTimeChange}/>
                        </Stack>
                    </VStack>
                    <Divider/>
                    <VStack spacing={4} w={'100%'}>
                        <Heading fontSize={[14, 14, 16]} w={'100%'} textAlign={'left'}
                                 textTransform='uppercase'>
                            {t('Promotions')}
                        </Heading>
                        <Stack direction={['column', 'column', 'row']} w={'100%'} textAlign={'left'}>
                            <CustomSkeleton height={'88'} isLoaded={!loadingDiscounts}>
                                <UnorderedList spacing={2} w={'100%'}>
                                    {
                                        Object.entries(allDiscounts as Discount[]).map(([key, d]) => (
                                            d.DiscountType === "Time" ?
                                            <ListItem key={key}>{t('MoreThan')}{d.MinHours}hrs. - {d.Percent}%
                                                off</ListItem> : []
                                        ))
                                    }
                                </UnorderedList>
                            </CustomSkeleton>
                        </Stack>
                    </VStack>
                    <Divider/>
                    <HStack spacing={2} w={'100%'}>
                        <FontAwesomeIcon fontSize={22} icon={faCircleInfo}/>
                        <Text fontSize={[14, 16]}>
                            {t('ExtensionSubjectToAvailability')}
                        </Text>
                    </HStack>
                    <HStack w={'100%'} justifyContent={'center'} position={'relative'} bottom={0}>
                        <PrevBtn prevStep={handleGoToPrev}/>
                        <NextBtn
                            nextStep={goToNext}/>
                    </HStack>
                </VStack>
            </CardBody>
        </Card>
    );
}
