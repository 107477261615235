import {Payment, State} from "./admin";
import {User} from "./user";

export interface MaxTimeRange {
    Backwards: number
    Forwards: number
}

export interface Locker {
    ID: number
    Num: number
    Pin: string
    Blocked: boolean
    Booked: boolean
    Open: boolean
    BookingCode: string
    BookedFrom: string
    BookedTo: string
    Column: number
    LetterSize: boolean
    SmallSize: boolean
    MainComputer: boolean
    IsVault: boolean
}

export interface Booking {
    ID: string
    Code: string
    From: string
    To: string
    Discounts: Discount[]
    Payments: Payment[]
    LastPayment: Payment | null
    States: State[]
    LastState: State
    Spaces: Map<string, Space>
    Type: string
    Branch: Branch
    Customer: Customer
    User: User | null
    LastPrice: number
    AdditionalInfo: Map<string, string>
    PaymentLink: string
    CreatedAt: string
    UpdatedAt: string
}

export interface Space {
    ID: string
    Number: string
    Description: string
    Size: Size
    PinNumber: string | null
    TerminalHostname: string
    Type: string
    BranchID: string
    IsDisabled: boolean
    BlockedFrom: string | null
    BlockedTo: string | null
    IsMainComputer: boolean
    TerminalColumn: number | null
    IsDeleted: boolean
    CreatedAt: string
    UpdatedAt: string
}

export interface NewBooking {
    From: string
    To: string
    SelectedSizes: Object
    BranchID: string
    Customer: Customer
    AdditionalInfo: Object
    Type: string
    Referrer: string | null
}

export const TypeNormal = "Normal"
export const TypeInternal = "Internal"
export const TypeCourtesy = "Courtesy"


export interface ClientInfo {
    Browser: string
    BrowserVersion: string
    OS: string
    OSVersion: string
    DeviceType: string
    DeviceModel: string
}

export interface BookingDetail {
    From: string
    To: string
    SelectedSizes: SelectedSize[]
    Location: string
    Branch: Branch
    Customer: Customer
}

export interface PayExtraTime {
    From: string
    To: string
    ExceededHrs?: number
    BookingID: string
    TimeZone: string
}

export interface NewExtension {
    From: string
    To: string
    Exceeded?: boolean
    ExceededHrs?: number
    BookingID: string
    TimeZone: string
}

export interface Customer {
    Name: string
    LastName: string
    Email: string
    Phone: string
    DiscoveryMethod: string
    Language: string
    TimeZone?: string
}

export interface AvailableSize {
    Size: Size
    SizeQty: number
}

export interface SelectedSize {
    Size: Size
    SizeQty: number
}

export interface StorageSize {
    ID: string
    Description: string
    Type: string
    Width: number
    Height: number
    Depth: number
    Price: number
    Capacity: string
    ViewOrder: number
    CreatedAt: string
    UpdatedAt: string
    SizeQty: number
}

export interface Size {
    ID: string
    Description: Map<string, string>
    Type: string
    Width: number
    Height: number
    Depth: number
    Price: number
    ViewOrder: number
    Capacity: Map<string, string>
    CreatedAt: string
    UpdatedAt: string
}

export interface Branch {
    ID: string
    Description: string
    Address: string
    MapAddress: string
    City: string
    IsDisabled: boolean
    IsComingSoon: boolean
    CreatedAt: string
    UpdatedAt: string
}

export interface Discount {
    ID: string
    Description: string
    MinHours: number
    Percent: number
    DiscountType: string
    CreatedAt: string
    UpdatedAt: string
}