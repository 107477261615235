import {addHours, format, isAfter, parse, set} from "date-fns";
import React, {useEffect, useState} from "react";
import {Badge, HStack, Menu, MenuButton, MenuItem, MenuList, Text} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {Booking, Discount} from "../../interfaces/interfaces";
import {useTranslation} from "react-i18next";


export const TimePicker = ({
                               booking = {} as Booking | null,
                               showNowItem = false,
                               isExtension = false,
                               hrs = [] as number[],
                               isFromPicker = false,
                               now = false,
                               setNow = [] as any,
                               fromTime = set(new Date(), {seconds: 0, milliseconds: 0}),
                               time = set(new Date(), {seconds: 0, milliseconds: 0}),
                               discounts = {} as Map<string, Discount> | never[],
                               setTime = [] as any
                           }) => {
    const {t} = useTranslation('dateSelection');
    const [actualDateTime] = useState(set(new Date(), {seconds: 0, milliseconds: 0}))

    const formattedTime = (hr: number) => {
        if (isFromPicker) {
            return format(set(time, {hours: hr, minutes: 0}), 'HH:mm')
        }

        return format(set(time, {hours: hr}), 'HH:mm')
    }

    const handleItemClick = (value: string) => {
        setTime(parse(value, 'HH:mm', new Date()))
    }

    const handleSetNow = () => {
        setNow(true)
        setTime(set(new Date(), {seconds: 0, milliseconds: 0}))
    }

    const stringTime = () => {
        return format(time, 'HH:mm')
    }

    const toTime = (hr: number) => {
        return set(time, {hours: hr})
    }

    const fromTimeIsAfterTimeNow = () => {
        return booking ? isAfter(booking.From, actualDateTime) : true
    }

    return (
        <Menu matchWidth={true} placement={'bottom'} flip={false}>
            <MenuButton
                bgColor={'secondary.500'}
                borderColor={'lightBrand'}
                outline={'none'}
                py={2}
                w={'100%'}
                transition='all 0.2s'
                borderRadius='md'
                borderWidth='1px'
                _hover={{borderColor: 'lightBrand'}}
                _focus={{borderColor: 'lightBrand'}}

            >
                <HStack justify={'center'} w={'100%'}>
                    <Text me={2}>
                        {now ? t('Now') : format(time, 'HH:mm') + " (ARG)"}
                    </Text>
                    {
                        (isFromPicker && fromTimeIsAfterTimeNow()) || !isFromPicker ?
                            <FontAwesomeIcon icon={faChevronDown}/>
                            : []
                    }
                </HStack>
            </MenuButton>
            {
                (isFromPicker && fromTimeIsAfterTimeNow()) || !isFromPicker ?
                    <MenuList
                        opacity={0.8} bgColor={'secondary.500'}
                        borderColor={'lightBrand'} overflowY={'scroll'} maxHeight={200} w={'100%'}>

                        {
                            showNowItem ?
                                <MenuItem
                                    onClick={handleSetNow}
                                    my={2}
                                    bgColor={now ? 'primary.500' : 'secondary.500'}
                                    color={'lightBrand'}
                                    justifyContent={'center'}
                                    _hover={{bgColor: 'primary.500'}}>
                                    {t('Now')}
                                </MenuItem>
                                : []
                        }

                        {hrs.map((hr, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => handleItemClick(formattedTime(hr))}
                                value={formattedTime(hr)}
                                my={2}
                                bgColor={stringTime() === formattedTime(hr) ? 'primary.500' : 'secondary.500'}
                                color={'lightBrand'}
                                justifyContent={'center'}
                                _hover={{bgColor: 'primary.500'}}>
                                {formattedTime(hr)} (ARG)

                                {!isFromPicker && !isExtension ?
                                    <>
                                        {discounts ? (() => {
                                            const bestDiscount = Object.entries(discounts)
                                                .filter(([_,discount]) => discount.DiscountType === "Time")
                                                .sort((a, b) => b[1].Percent - a[1].Percent)
                                                .find(([_, discount]) => isAfter(toTime(hr), addHours(fromTime, discount.MinHours - 1)))

                                            return bestDiscount ? (
                                                <Badge
                                                    onClick={() => handleItemClick(formattedTime(hr))}
                                                    ml="1"
                                                    fontSize="sm"
                                                    colorScheme="green"
                                                >
                                                    {bestDiscount[1].Percent}% OFF
                                                </Badge>
                                            ) : null
                                        })() : null}
                                    </>
                                    : []
                                }
                            </MenuItem>
                        ))}
                    </MenuList>
                    : []
            }
        </Menu>
    )
}